// src/config.js

export const getConfig = (url) => {
    const configMap = {
        'idoconversa.com.br': {
            nameTitle: 'Ido Conversa',
            pageTitle: 'IdoConversa - Um jeito único de fazer negócios!',
            logoUrl: 'images/logo_idoconversa.png',
            chatUrl: 'https://chat.idoconversa.com.br/',
            signupUrl: '/assine',
            secConquisteTitle: 'Conquiste clientes e impulsione seu negócio com o Whatsapp!',
            footerMessage: 'Copyright © 2022 Idoorder, Inc. All rights reserved.',
            secConquisteDescript: 'Desenvolvido especialmente para empresários como você, que não são programadores, mas desejam atender clientes de uma forma inteligente e prática.',
            faviconUrl: 'images/favicon_idoconversa.png',
            planos: [
              {
                title: 'Básico',
                trial: '2 dias grátis',
                price: 'R$179,00/mês',
                features: [
                  { 
                    active: true,
                    text: '1 Conexão'
                  },
                  {
                    active: true,
                    text: 'Até 5 atendentes'
                  },
                  {
                    active: true,
                    text: 'Menus ilimitados'
                  },
                  {
                    active: true,
                    text: 'Respostas ilimitadas'
                  },
                  {
                    active: true,
                    text: 'Contatos ilimitados'
                  },
                  {
                    active: true,
                    text: 'CRM'
                  },
                  {
                    active: true,
                    text: 'Flow Builder'
                  },
                  {
                    active: false,
                    text: 'Mensagens agendadas'
                  },
                  {
                    active: false,
                    text: 'Chat interno'
                  },
                  {
                    active: false,
                    text: 'Integração com Messenger'
                  },
                  {
                    active: false,
                    text: 'Integração com Instagram'
                  },
                ]
              },
              {
                title: 'Premium',
                trial: '2 dias grátis',
                price: 'R$229,00/mês',
                features: [
                  {active: true,
                    text: '2 Conexões'
                  },
                  {active: true,
                    text: 'Até 10 atendentes'
                  },
                  {active: true,
                    text: 'Menus ilimitados'
                  },
                  {active: true,
                    text: 'Respostas ilimitadas'
                  },
                  {active: true,
                    text: 'Contatos ilimitados'
                  },
                  {active: true,
                    text: 'CRM'
                  },
                  {active: true,
                    text: 'Flow Builder'
                  },
                  {active: true,
                     text: 'Mensagens agendadas'
                  },
                  {active: true,
                    text: 'Chat interno'
                  },
                  {
                    active: false,
                  text: 'Integração com Messenger'
                  },
                  {
                    active: false,
                  text: 'Integração com Instagram'
                  },
                ]
              },
              {
                title: 'Profissional',
                trial: '2 dias grátis',
                price: 'R$269,00/mês',
                features: [
                  {active: true,
                    text: '3 Conexões'
                  },
                  {active: true,
                    text: 'Até 20 atendentes'
                  },
                  {active: true,
                    text: 'Menus ilimitados'
                  },
                  {active: true,
                    text: 'Respostas ilimitadas'
                  },
                  {active: true,
                    text: 'Contatos ilimitados'
                  },
                  {active: true,
                    text: 'CRM'
                  },
                  {active: true,
                    text: 'Flow Builder'
                  },
                  {active: true,
                    text: 'Mensagens agendadas'
                  },
                  {active: true,
                    text: 'Chat interno'
                  },
                  {active: true,
                     text: 'Integração com Messenger'
                  },
                  {active: true,
                     text: 'Integração com Instagram'
                  },
                ]
              }
            ],
            videoSection: {
              visible: true
            },
            whiteLabel: {
              title: 'White Label',
              description: 'Tenha sua própria revenda de chatbot',
              price: 'CONSULTE',
              details: 'Empreenda em um mercado gigantesco que não para de crescer. Entregamos um sistema de gestão de chatbots completo com a sua identidade pronto para sair vendendo.'
            },
            testButton: {
              textStart: 'Cadastre-se',
              textTrial: 'Testar grátis',
              visible: false
              //false para trial ativo
            },
            testButtonWhiteLabel: {
                visible: true
              },
          faq: {
            title: 'Perguntas e respostas',
            subtitle: 'As dúvidas mais comuns',
            description: 'Não encontrou sua resposta?',
            contactUrl: 'https://wa.me/557391216088?text=Contato%20Vindo%20do%20Site',
            items: [
                {
                    question: 'Não sou bom com tecnologia. Como vou saber o que fazer?',
                    answer: 'Você terá acesso a uma área de membros com todos vídeos explicativos, sem enrolação e direto ao ponto para te ensinar tudo o que você precisa para vender no automático. Sempre que você tiver dúvidas você poderá recorrer ao nosso time de suporte.'
                },
                {
                    question: 'É difícil conectar o Whatsapp?',
                    answer: 'A conexão com o Bot é a mesma realizada com o WhatsApp web. Basta escanear o QR Code da plataforma e seu WhatsApp estará conectado.'
                },
                {
                    question: 'Quantos números de WhatsApp consigo conectar na plataforma?',
                    answer: 'Cada plano conta com um número de "conexões". Cada conexão permite adicionar uma conta de Whatsapp. Se precisar de mais conexões, basta acionar o nosso time e solicitar conexões adicionais.'
                },
                {
                    question: 'Não quero configurar a plataforma. Há alguém disponível para fazer isso por mim?',
                    answer: 'Sim. Temos equipe disponível para contratação a partir de R$ 100,00. Para construção de fluxos simples até automações altamente complexas.'
                }
            ]
        }
    },
      'localhostbkp': {
        nameTitle: 'LocalHost',
        pageTitle: 'IdoConversa - Um jeito único de fazer negócios!',
        logoUrl: 'images/logo_idoconversa.png',
        chatUrl: 'https://chat.idoconversa.com.br/',
        signupUrl: '/assine',
        secConquisteTitle: 'Conquiste clientes e impulsione seu negócio com o Whatsapp!',
        secConquisteDescript: 'Desenvolvido especialmente para empresários como você, que não são programadores, mas desejam atender clientes de uma forma inteligente e prática.',
        footerMessage: 'Copyright © 2022 Idoorder, Inc. All rights reserved.',
        faviconUrl: 'images/favicon_idoconversa.png',
        planos: [
          {
            title: 'Básico',
            trial: '2 dias grátis',
            price: 'R$179,00/mês',
            features: [
              { 
                active: true,
                text: '1 Conexão'
              },
              {
                active: true,
                text: 'Até 5 atendentes'
              },
              {
                active: true,
                text: 'Menus ilimitados'
              },
              {
                active: true,
                text: 'Respostas ilimitadas'
              },
              {
                active: true,
                text: 'Contatos ilimitados'
              },
              {
                active: true,
                text: 'CRM'
              },
              {
                active: true,
                text: 'Flow Builder'
              },
              {
                active: false,
                text: 'Mensagens agendadas'
              },
              {
                active: false,
                text: 'Chat interno'
              },
              {
                active: false,
                text: 'Integração com Messenger'
              },
              {
                active: false,
                text: 'Integração com Instagram'
              },
            ]
          },
          {
            title: 'Premium',
            trial: '2 dias grátis',
            price: 'R$229,00/mês',
            features: [
              {active: true,
                text: '2 Conexão'
              },
              {active: true,
                text: 'Até 10 atendentes'
              },
              {active: true,
                text: 'Menus ilimitados'
              },
              {active: true,
                text: 'Respostas ilimitadas'
              },
              {active: true,
                text: 'Contatos ilimitados'
              },
              {active: true,
                text: 'CRM'
              },
              {active: true,
                text: 'Flow Builder'
              },
              {active: true,
                 text: 'Mensagens agendadas'
              },
              {active: true,
                text: 'Chat interno'
              },
              {
                active: false,
              text: 'Integração com Messenger'
              },
              {
                active: false,
              text: 'Integração com Instagram'
              },
            ]
          },
          {
            title: 'Profissional',
            trial: '2 dias grátis',
            price: 'R$269,00/mês',
            features: [
              {active: true,
                text: '3 Conexão'
              },
              {active: true,
                text: 'Até 20 atendentes'
              },
              {active: true,
                text: 'Menus ilimitados'
              },
              {active: true,
                text: 'Respostas ilimitadas'
              },
              {active: true,
                text: 'Contatos ilimitados'
              },
              {active: true,
                text: 'CRM'
              },
              {active: true,
                text: 'Flow Builder'
              },
              {active: true,
                text: 'Mensagens agendadas'
              },
              {active: true,
                text: 'Chat interno'
              },
              {active: true,
                 text: 'Integração com Messenger'
              },
              {active: true,
                 text: 'Integração com Instagram'
              },
            ]
          }
        ],
        videoSection: {
          visible: true
        },
        whiteLabel: {
          title: 'White Label',
          description: 'Tenha sua própria revenda de chatbot',
          price: 'CONSULTE',
          details: 'Empreenda em um mercado gigantesco que não para de crescer. Entregamos um sistema de gestão de chatbots completo com a sua identidade pronto para sair vendendo.'
        },
        testButton: {
          textStart: 'Cadastre-se',
          textTrial: 'Testar grátis',
          visible: false
          //false para trial ativo
        },
        testButtonWhiteLabel: {
            visible: true
          },
        faq: {
            title: 'Perguntas e respostas',
            subtitle: 'As dúvidas mais comuns',
            description: 'Não encontrou sua resposta?',
            contactUrl: 'https://wa.me/557391216088?text=Contato%20Vindo%20do%20Site',
            items: [
                {
                    question: 'Não sou bom com tecnologia. Como vou saber o que fazer?',
                    answer: 'Você terá acesso a uma área de membros com todos vídeos explicativos, sem enrolação e direto ao ponto para te ensinar tudo o que você precisa para vender no automático. Sempre que você tiver dúvidas você poderá recorrer ao nosso time de suporte.'
                },
                {
                    question: 'É difícil conectar o Whatsapp?',
                    answer: 'A conexão com o Bot é a mesma realizada com o WhatsApp web. Basta escanear o QR Code da plataforma e seu WhatsApp estará conectado.'
                },
                {
                    question: 'Quantos números de WhatsApp consigo conectar na plataforma?',
                    answer: 'Cada plano conta com um número de "conexões". Cada conexão permite adicionar uma conta de Whatsapp. Se precisar de mais conexões, basta acionar o nosso time e solicitar conexões adicionais.'
                },
                {
                    question: 'Não quero configurar a plataforma. Há alguém disponível para fazer isso por mim?',
                    answer: 'Sim. Temos equipe disponível para contratação a partir de R$ 100,00. Para construção de fluxos simples até automações altamente complexas.'
                }
            ]
        }
    },
      'chipchat.com.br': {
        nameTitle: 'Chip Chat',
        pageTitle: 'Chip Chat - Um jeito único de fazer negócios!',
        logoUrl: 'images/logo_chipchat.png',
        chatUrl: 'https://chat.chipchat.com.br/',
        signupUrl: 'https://chat.chipchat.com.br/signup',
        secConquisteTitle: 'Conquiste clientes e impulsione seu negócio com o Whatsapp!',
        setConquisteDescript: 'Desenvolvido especialmente para empresários como você, que não são programadores, mas desejam atender clientes de uma forma inteligente e prática.',
        footerMessage: 'Copyright © 2024 chipchat, Inc. All rights reserved.',
        faviconUrl: 'images/favicon_chipchat.png',
        
        planos: [
          {
            title: 'Básico',
            trial: '7 dias grátis',
            price: 'R$149,00/mês',
            features: [
              { 
                active: true,
                text: '1 Conexão'
              },
              {
                active: true,
                text: 'Até 5 atendentes'
              },
              {
                active: true,
                text: 'Menus ilimitados'
              },
              {
                active: true,
                text: 'Respostas ilimitadas'
              },
              {
                active: true,
                text: 'Contatos ilimitados'
              },
              {
                active: true,
                text: 'CRM'
              },
              {
                active: true,
                text: 'Flow Builder'
              },
              {
                active: false,
                text: 'Mensagens agendadas'
              },
              {
                active: false,
                text: 'Chat interno'
              },
              {
                active: false,
                text: 'Integração com Messenger'
              },
              {
                active: false,
                text: 'Integração com Instagram'
              },
            ]
          },
          {
            title: 'Premium',
            trial: '7 dias grátis',
            price: 'R$199,00/mês',
            features: [
              {active: true,
                text: '2 Conexões'
              },
              {active: true,
                text: 'Até 10 atendentes'
              },
              {active: true,
                text: 'Menus ilimitados'
              },
              {active: true,
                text: 'Respostas ilimitadas'
              },
              {active: true,
                text: 'Contatos ilimitados'
              },
              {active: true,
                text: 'CRM'
              },
              {active: true,
                text: 'Flow Builder'
              },
              {active: true,
                 text: 'Mensagens agendadas'
              },
              {active: true,
                text: 'Chat interno'
              },
              {
                active: false,
              text: 'Integração com Messenger'
              },
              {
                active: false,
              text: 'Integração com Instagram'
              },
            ]
          },
          {
            title: 'Profissional',
            trial: '7 dias grátis',
            price: 'R$249,00/mês',
            features: [
              {active: true,
                text: '3 Conexões'
              },
              {active: true,
                text: 'Até 20 atendentes'
              },
              {active: true,
                text: 'Menus ilimitados'
              },
              {active: true,
                text: 'Respostas ilimitadas'
              },
              {active: true,
                text: 'Contatos ilimitados'
              },
              {active: true,
                text: 'CRM'
              },
              {active: true,
                text: 'Flow Builder'
              },
              {active: true,
                text: 'Mensagens agendadas'
              },
              {active: true,
                text: 'Chat interno'
              },
              {active: true,
                 text: 'Integração com Messenger'
              },
              {active: true,
                 text: 'Integração com Instagram'
              },
            ]
          }
        ],
        videoSection: {
          visible: false
        },
          whiteLabel: {
            title: 'White Label',
            description: 'Tenha sua própria revenda de chatbot',
            price: 'CONSULTE',
            details: 'Empreenda em um mercado gigantesco que não para de crescer. Entregamos um sistema de gestão de chatbots completo com a sua identidade pronto para sair vendendo.'
          },
          testButton: {
            textStart: 'Cadastre-se',
            textTrial: 'Testar grátis',
            visible: true
            //false para trial ativo
          },
          testButtonWhiteLabel: {
            visible: false
          },
          faq: {
            title: 'Perguntas e respostas',
            subtitle: 'As dúvidas mais comuns',
            description: 'Não encontrou sua resposta?',
            contactUrl: 'https://wa.me/5571984355011?text=Contato%20Vindo%20do%20Site',
            items: [
                {
                    question: 'Não sou bom com tecnologia. Como vou saber o que fazer?',
                    answer: 'Você terá acesso a uma área de membros com todos vídeos explicativos, sem enrolação e direto ao ponto para te ensinar tudo o que você precisa para vender no automático. Sempre que você tiver dúvidas você poderá recorrer ao nosso time de suporte.'
                },
                {
                    question: 'É difícil conectar o Whatsapp?',
                    answer: 'A conexão com o Bot é a mesma realizada com o WhatsApp web. Basta escanear o QR Code da plataforma e seu WhatsApp estará conectado.'
                },
                {
                    question: 'Quantos números de WhatsApp consigo conectar na plataforma?',
                    answer: 'Cada plano conta com um número de "conexões". Cada conexão permite adicionar uma conta de Whatsapp. Se precisar de mais conexões, basta acionar o nosso time e solicitar conexões adicionais.'
                },
                {
                    question: 'Não quero configurar a plataforma. Há alguém disponível para fazer isso por mim?',
                    answer: 'Sim. Temos equipe disponível para contratação a partir de R$ 100,00. Para construção de fluxos simples até automações altamente complexas.'
                }
            ]
        }
    },
    'hubot.app.br': {
     // 'localhost': {
      nameTitle: 'Hubot',
      pageTitle: 'Hubot - Um jeito único de fazer negócios!',
      logoUrl: 'images/logo_hubot.png',
      chatUrl: 'https://chat.hubot.app.br/',
      signupUrl: 'https://wa.me/558187327365?text=Contato%20Vindo%20do%20Site',
      secConquisteTitle: 'Conquiste clientes e impulsione seu negócio com o Whatsapp!',
      setConquisteDescript: 'Desenvolvido especialmente para empresários como você, que não são programadores, mas desejam atender clientes de uma forma inteligente e prática.',
      footerMessage: 'Copyright © 2024 hubot, Inc. All rights reserved.',
      faviconUrl: 'images/favicon_hubot.png',
      videoSection: {
        visible: false
      },
        whiteLabel: {
          title: 'White Label',
          description: 'Tenha sua própria revenda de chatbot',
          price: 'CONSULTE',
          details: 'Empreenda em um mercado gigantesco que não para de crescer. Entregamos um sistema de gestão de chatbots completo com a sua identidade pronto para sair vendendo.'
        },
        testButton: {
          textStart: 'Cadastre-se',
          textTrial: 'Testar grátis',
          visible: true
          //false para trial ativo
        },
        testButtonWhiteLabel: {
          visible: false
        },
        faq: {
          title: 'Perguntas e respostas',
          subtitle: 'As dúvidas mais comuns',
          description: 'Não encontrou sua resposta?',
          contactUrl: 'https://wa.me/558187327365?text=Contato%20Vindo%20do%20Site',
          items: [
              {
                  question: 'Não sou bom com tecnologia. Como vou saber o que fazer?',
                  answer: 'Você terá acesso a uma área de membros com todos vídeos explicativos, sem enrolação e direto ao ponto para te ensinar tudo o que você precisa para vender no automático. Sempre que você tiver dúvidas você poderá recorrer ao nosso time de suporte.'
              },
              {
                  question: 'É difícil conectar o Whatsapp?',
                  answer: 'A conexão com o Bot é a mesma realizada com o WhatsApp web. Basta escanear o QR Code da plataforma e seu WhatsApp estará conectado.'
              },
              {
                  question: 'Quantos números de WhatsApp consigo conectar na plataforma?',
                  answer: 'Cada plano conta com um número de "conexões". Cada conexão permite adicionar uma conta de Whatsapp. Se precisar de mais conexões, basta acionar o nosso time e solicitar conexões adicionais.'
              },
              {
                  question: 'Não quero configurar a plataforma. Há alguém disponível para fazer isso por mim?',
                  answer: 'Sim. Temos equipe disponível para contratação a partir de R$ 100,00. Para construção de fluxos simples até automações altamente complexas.'
              }
          ]
      }
  },
      // Adicione mais configurações conforme necessário
    };
  
    for (const key in configMap) {
      if (url.includes(key)) {
        return configMap[key];
      }
    }
  
    // Configuração padrão, caso a URL não corresponda a nenhuma chave
    return {
        logoUrl: 'images/logotipo2.png',
        chatUrl: '#',
        signupUrl: '#',
        secConquisteTitle: 'Default Title',
        secConquisteDescript: 'Default Description',
        planos: [
          // Estrutura padrão de planos
        ],
        whiteLabel: null,
        testButton: {
          text: 'Testar grátis',
          visible: false
        }
      };
    };
  